<template lang="pug">
div
  .form-card-title
    inline-svg.form-card-title-icon(
      v-if="title_icon",
      :src="require(`../../../assets/icons/form/header/${title_icon}`)"
    )
    span {{ title }}
    span.form-card-status(v-if="statusTitle", :style="statusStyle") {{ statusTitle }}
    inline-svg.form-card-close-icon(
      v-if="!loading",
      :src="require('../../../assets/icons/form/header/close.svg')",
      @click="closeForm()"
    )

  .form-card-title-spacer
</template>

<script>
export default {
  components: {},

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      grid: this.parentData.grid,
      title_icon: this.parentData.title_icon,
      loading: true,
    };
  },

  computed: {
    title() {
      return this.parentData.title;
    },
    status() {
      return this.parentData.status;
    },
    statusTitle() {
      return this.status?.statusTitle;
    },
    statusStyle() {
      return `background-color: ${this.parentData.status.statusColor || "transparent"}`;
    },
  },

  methods: {
    closeForm() {
      if (this.grid) {
        this.resetForm();
      }
      this.$emit("close-form", {});
    },

    setLoading(val) {
      this.loading = val;
    },
  },
};
</script>

<style lang="scss">
.form-card-status {
  margin-left: 16px;
  padding: 2px 10px;
  border-radius: 40px;
  color: white;
  font-size: 13px !important;
}
.form-card-title {
  align-items: center;
}
</style>
