<template lang="pug">
div
  form-header(
    v-if="data",
    ref="form-header",
    :parentData="{ title: `${data.form_header.title} № ${row.id}`, title_icon: 'show_header.svg' }"
  )

  div(v-for="block in data['blocks']", :class="`show-card-info ${block.class}`")
    .row-title(v-if="block.name", style="margin: 10px; font-size: 20px; font-weight: bold") {{ block.name }}
    .row-info-parts(v-for="part in block.parts")
      .spacer(v-if="block.spacer")
      .row-info-field(v-for="field in part", style="width: 100%")
        span.row-label {{ field.label }}
        span.row-value(v-if="field.type === 'description'", v-html="row[field.value]")
        span.row-value(v-else) {{ getFieldText(field) }}
</template>

<script setup>
import { computed } from "vue";
import formHeader from "../../../shared/forms/formHeader";

const props = defineProps({
  /*
  Основные данные

  Пример: { row: данные заявки, data: { form_header: поля хедера, blocks: блоки с данными } }
  */
  parentData: {
    type: Object,
    default: () => {},
  },
});

const row = computed(() => props.parentData.row);
const data = computed(() => props.parentData.data);

const getFieldText = field => {
  return row.value[field.value] || "-";
};
</script>
