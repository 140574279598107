<template lang="pug">
.container(v-if="isOpened")
  template(v-if="!loading")
    q-tabs(v-model="tab")
      q-tab(:name="'header'", :label="issueLocales.main_information")
      q-tab(:name="'description'", :label="issueLocales.additional_information")
    q-tab-panels(v-model="tab", animated, keep-alive)
      ExtraTabPanel(:data="headerData", :field="field", :name="'header'", :showEmpty="showEmpty")
      ExtraTabPanel(:data="descriptionData", :field="field", :name="'description'", :showEmpty="showEmpty")

  .spinner-container.form-spinner(v-else)
    q-spinner(color="primary", size="3em")
</template>

<script setup>
import { onMounted, ref, computed } from "vue";
import backend from "@/api";

import { issueLocales } from "@/services/useLocales";

import { handleError } from "@/services/handleErrors";
import { handleDateRow } from "@/services/reformatStrings";

import ExtraTabPanel from "./dynamic_issues_extra_tabs_panel.vue";

const props = defineProps({
  /* 
  Значение открытости дополнительной информации. Булеан
  */
  isOpened: {
    type: Boolean,
    required: true,
  },
  /*
  Данные поля

  Пример данных:
  'field': {
    "label": "Строение",
    "value": "building_full_title",
    "extra": {
      "label": "Подробнее",
      "template_id": 17,
      "object_id": 6,
      "path": "extra_object_information"
    }
  }
  */
  field: {
    type: Object,
    required: true,
  },
  /* Показатель показа пустых полей */
  showEmpty: {
    type: Boolean,
    required: true,
  },
});

const loading = ref(true);
const addData = ref();
const tabs = ref(["header", "description"]);
const tab = ref("header");

const isFloorPlanShown = ref(false);

const headerData = computed(() => addData.value.blocks.find(el => el.type === "header").fields || []);

const descriptionData = computed(() => addData.value.blocks.find(el => el.type === "description").fields || []);

const getInitialData = async () => {
  try {
    const { data } = await backend.index(
      `api/v3/tech_passport/objects/${props.field.extra.object_id}?template_id=${props.field.extra.template_id}&filters={}`,
    );

    addData.value = data;

    loading.value = false;
  } catch (e) {
    await handleError(e);
  }
};

onMounted(async () => {
  await getInitialData();
});
</script>

<style lang="scss" scoped>
:deep(.q-tab) {
  flex-grow: 1;
}
.container {
  background-color: var(--show-card-expanded-panel);
  border-radius: 16px 0 16px 16px;
  min-height: 100px;
  margin-top: 16px;

  .spinner-container {
    height: 100px;
  }
}
</style>
