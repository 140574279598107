import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const i18nLocales = {
  en: {
    total: "Total",
    facility: "Facility",
    company: "Company",
    user: "User",
    date: {
      days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      daysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      monthsShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    },
    selectAttributes: "Select attributes",
    buttonCloseLabel: "Choose",
    buttonCancelLabel: "Cancel",
    yes: "Yes",
    no: "No",
    ad: "Login from personal account",
    items: "items",
    scheduler: {
      titleNew: "Add scheduler",
      titleEdit: "Edit scheduler",
      daily: "By days",
      weekly: "By weeks",
      monthly: "By months",
      early: "By years",
      step1: "Step 1",
      step2: "Step 2",
      step3: "Step 3",
      step4: "Step 4",
      schedulerName: "Name",
      facility: "Facility",
      object: "Object",
      building: "Building",
      ppr: "MMT level:",
      groupSystem: "Group System",
      system: "System",
      equipment: "Equipment",
      events: "Events",
      event: "Event",
      eventName: "Event name",
      periodicity: "Periodicity",
      issuesPeriodicity: "Issue creation frequency",
      startedAt: "Started at",
      finishedAt: "Finished at",
      deadline: "Issue expires in (days)",
      save: "Save",
      works: "Works",
      worksNotAllowed: "No allowed works",
      worksNormhours: "Normal hours work",
      worksCost: "Works cost",
      materials: "Materials",
      materialsNotAllowed: "No allowed materials",
      materialsCost: "Materials cost",
      totalCost: "Total cost",
      description: "Description",
      addEvent: "Add event",
      submit: "Schedule",
      equipmentPlanning: "EQUIPMENT PLANNING",
      objectPlanning: "PLANNING BY OBJECTS",
      historyButton: "Show history",
    },
    history: {
      name: "Name",
      was: "Was",
      became: "Became",
    },
    dashboard: "Dashboard",
  },
  ru: {
    total: "Итого",
    facility: "Основная организация",
    company: "Организация",
    user: "Пользователь",
    date: {
      days: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
      months: [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
      ],
      daysShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
      monthsShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
    },
    selectAttributes: "Выберите атрибуты",
    buttonCloseLabel: "Применить",
    buttonCancelLabel: "Отменить",
    yes: "Да",
    no: "Нет",
    ad: "Вход через личный кабинет",
    items: "шт",
    scheduler: {
      titleNew: "Добавление планирования",
      titleEdit: "Редактирование планирования",
      daily: "По дням",
      weekly: "По неделям",
      monthly: "По месяцам",
      early: "По годам",
      step1: "Шаг 1",
      step2: "Шаг 2",
      step3: "Шаг 3",
      step4: "Шаг 4",
      schedulerName: "Название",
      facility: "Основная организация",
      object: "Объект",
      building: "Строение",
      ppr: "Уровень ППР:",
      groupSystem: "Группа систем",
      system: "Системы",
      equipment: "Оборудование",
      events: "Мероприятия",
      event: "Мероприятие",
      eventName: "Название мероприятия",
      periodicity: "Периодичность",
      issuesPeriodicity: "Частота создания заявок",
      startedAt: "Дата начала",
      finishedAt: "Дата окончания",
      deadline: "Заявка уходит в просрок через (дней)",
      save: "Сохранить",
      works: "Работы",
      worksNotAllowed: "Нет доступных работ",
      worksNormhours: "Нормочасов работ",
      worksCost: "Стоимость работ",
      materials: "Материалы",
      materialsNotAllowed: "Нет доступных материалов",
      materialsCost: "Стоимость материалов",
      totalCost: "Итого стоимость",
      description: "Описание",
      addEvent: "Добавить мероприятие",
      submit: "Запланировать",
      equipmentPlanning: "ПЛАНИРОВАНИЕ ПО ОБОРУДОВАНИЮ",
      objectPlanning: "ПЛАНИРОВАНИЕ ПО ОБЪЕКТАМ",
      historyButton: "Просмотреть историю",
    },
    history: {
      name: "Название",
      was: "Было",
      became: "Стало",
    },
    dashboard: "Дашборд",
  },
};

export default new VueI18n({
  locale: "ru",
  messages: i18nLocales,
});
