<template lang="pug">
div(v-if="status && status !== 'none'")
  div
    .spinner-container.modal-spinner(v-if="!dataHasLoaded")
      q-spinner(color="primary", size="3em")

    div(v-if="dataHasLoaded")
      .checklist-form-button
        q-btn(flat, no-caps, @click="openForm()", :class="btn_class")
          template(slot="default")
            inline-svg.checklist-form-button-icon(:src="require(`../../../assets/icons/checklist/btn_icon.svg`)")

          template(slot="default")
            span.checklist-form-button-label {{ label }}

      q-dialog.checklist-form__wrapper(v-model="modal.form")
        checklist-form(
          ref="checklist-form",
          @close-form="closeForm($event)",
          @reload-form-data="loadFormData",
          :parentData="{ row: row, data: form_data, path: path, grid: grid, header_title: header_title }"
        )

      q-dialog.checklist-form__wrapper(v-model="modal.show")
        checklist-show(
          ref="checklist-show",
          @closeForm="closeForm",
          @reload-form-data="loadFormData",
          :parentData="{ row: row, data: show_data, path: path, grid: grid, header_title: header_title }"
        )
div(v-else)
</template>

<script>
import checklistForm from "./form";
import checklistLogs from "./logs";
import checklistShow from "./show";

export default {
  components: {
    checklistForm,
    checklistLogs,
    checklistShow,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      grid: "checklists",
      path: this.$store.state.paths["checklist"],
      form_data: [],
      show_data: [],
      status: "none",

      header_title: "Чеклист",

      dataHasLoaded: false,

      modal: {
        form: false,
        logs: false,
        show: false,
      },
    };
  },

  computed: {
    row() {
      return this.parentData.row;
    },

    parentGrid() {
      return this.parentData.grid;
    },

    btn_class() {
      return this.params_by_status[this.status].btn_class;
    },

    label() {
      return this.params_by_status[this.status].label;
    },
    checklist_locales() {
      return this.locales.checklist[this.current_locale];
    },
    params_by_status() {
      return {
        begin: {
          modal: "form",
          label: this.checklist_locales.start_checklist,
          btn_class: "",
        },
        continue: {
          modal: "form",
          label: "Продолжить чеклист",
          btn_class: "continue-btn",
        },
        show: {
          modal: "show",
          label: this.checklist_locales.show_checklist,
          btn_class: "show-btn",
        },
        none: {
          modal: "none",
          label: "none",
          btn_class: "none",
        },
      };
    },
  },

  mounted() {
    this.$store.commit("initialGridsState", { grid: this.grid, attr: {} });

    this.loadFormData();

    const appendix =
      this.parentGrid === "issues" ||
      this.parentGrid === "issue_center" ||
      this.parentGrid === "dynamic_issues" ||
      this.parentGrid === "archive"
        ? ""
        : "/ppr";

    this.$backend
      .index("/api/v3" + appendix + "/issues/" + this.parentData.row.id + "/checklist_data")
      .then(res => {
        this.show_data = res.data;
        this.status = "show";
      })
      .catch(() => {
        if (this.parentGrid === "archive" || this.parentGrid === "ppr_archive") this.status = "none";
        else this.status = "begin";
      });
  },

  beforeDestroy() {},

  methods: {
    openForm() {
      this.resetForm();
      this.modal[this.params_by_status[this.status]["modal"]] = true;
    },

    closeForm(event) {
      this.modal[this.params_by_status[this.status]["modal"]] = false;
      if (event.complete && event.data) {
        this.show_data = event.data;
        this.status = "show";
      }
    },

    loadFormData(next_el = undefined) {
      let path = this.path + "/" + this.row.checklist_id;
      this.$backend
        .index(path)
        .then(({ data }) => {
          this.form_data = data;

          if (next_el) {
            data.fields.forEach(field => {
              this.$store.commit("createFormField", { grid_name: this.grid, field: field.name });
              this.$refs["checklist-form"].setFieldVal({ field: field.name, val: field.value });
            });
            this.$refs["checklist-form"].setLoading(false);
          }
        })
        .catch(error => {
          console.log(error);
          if (error.response) {
            this.reLogin(error.response.status);
          }
        })
        .finally(() => {
          this.dataHasLoaded = true;
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/checklists/main";
</style>
