<template lang="pug">
div
  div(v-if="column.type === 'image'", @click.self="openShow()")
    img.grid-image(v-if="row[column.name]", :src="row[column.name]['url']")

  div(v-else-if="column.type === 'files_collection'")
    inline-svg.files-collection-icon(
      v-if="row[column.name].length > 0",
      :src="require('../../../assets/icons/grid/clip.svg')",
      @click="openFilesModal()"
    )

  div(v-else-if="column.type === 'icon'")
    q-icon.td-icon(:name="row.icon")

  div(v-else, @click.self="openShow()")
    inline-svg.icon-before-td-row(
      v-if="column.with_icon",
      :src="require(`../../../assets/icons/grid/${column.with_icon.icon}`)"
    )

    div(:class="[addColumnClass()]")
      | {{ handleRow() }}

      .td-title(:id="`td-title-${row.id}-${column.name}`")
        .title-msg
        .title-arrow
</template>

<script>
import i18n from "@/plugins/vue-i18n/index";

export default {
  components: {},

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      grid_key: this.parentData.grid_key,
    };
  },

  computed: {
    column() {
      return this.parentData.column;
    },

    row() {
      return this.parentData.row;
    },
  },

  created() {},

  mounted() {},

  methods: {
    openShow() {
      this.$emit("open-show", this.row);
    },

    openFilesModal() {
      this.$emit("open-files-modal", this.row[this.column.name]);
    },

    addColumnClass() {
      let column_class = "";
      if (this.column.type === "issue_state") {
        // previous behavior

        // column_class = `issue-state ${this.row["state"]}-badge`;
        column_class = `issue-state issue-state-bg`;
      }
      return column_class;
    },

    handleRow() {
      let result = this.row[this.column.name];

      if (this.column.type === "boolean") {
        result = this.row[this.column.name]
          ? i18n["messages"][this.current_locale]["yes"]
          : i18n["messages"][this.current_locale]["no"];
      } else if (this.column.type === "description") {
        result = this.handleDescriptionTd(this.row[this.column.name]);
      } else if (["datetime", "date"].includes(this.column.type)) {
        result = this.handleDateRow(this.row, this.column.name, this.column.format);
      }

      if (this.parentData?.grid === "issue_history") {
        if (result?.length > 90) {
          this.addTitleEvent(result);
          result = result.substring(0, 90) + "...";
        }
      } else if (this.parentData?.grid === "issue_works") {
        if (result?.length > 80) {
          this.addTitleEvent(result);
          result = result.substring(0, 80) + "...";
        }
      } else if (this.parentData?.grid === "issue_comments") {
        if (result?.length > 77) {
          this.addTitleEvent(result);
          result = result.substring(0, 77) + "...";
        }
      } else if (this.parentData?.grid === "issue_materials") {
        if (result?.length > 50) {
          this.addTitleEvent(result);
          result = result.substring(0, 50) + "...";
        }
      } else {
        if (result?.length > 30) {
          this.addTitleEvent(result);
          result = result.substring(0, 30) + "...";
        }
      }

      return result;
    },

    addTitleEvent(title) {
      this.$nextTick(() => {
        let td_el = document.getElementById(`td-${this.row.id}-${this.column.name}`);
        let title_el = document.getElementById(`td-title-${this.row.id}-${this.column.name}`);
        let grid_el = document.getElementById(`grid-${this.grid_key.toString()}`);

        td_el.addEventListener("mouseover", () => {
          this.showTitle(td_el, title_el, title);
        });
        td_el.addEventListener("mouseleave", () => {
          this.hideTitle(title_el);
        });

        if (grid_el) {
          grid_el.addEventListener("scroll", () => {
            this.hideTitle(title_el);
          });
        }
      });
    },

    showTitle(td_el, title_el, title) {
      let msg = title_el.querySelector(".title-msg");

      if (this.parentData.grid === "issue_history") title_el.style.width = "400px";

      if (msg.children.length === 0) {
        let span = document.createElement("span");
        span.textContent = title;
        msg.appendChild(span);
      }

      title_el.style.visibility = "visible";
      title_el.style.opacity = "1";
      title_el.style.top = td_el.getBoundingClientRect().top - title_el.clientHeight + "px";
      title_el.style.left =
        td_el.getBoundingClientRect().left -
        (title_el.getBoundingClientRect().width - td_el.getBoundingClientRect().width) / 2 +
        "px";
    },

    hideTitle(title_el) {
      title_el.style.opacity = "0";
      title_el.style.visibility = "hidden";
      let msg = title_el.querySelector(".title-msg");
      while (msg.hasChildNodes()) {
        msg.removeChild(msg.firstChild);
      }
    },
  },
};
</script>

<style lang="scss">
.icon-before-td-row {
  margin-right: 10px;
  vertical-align: middle;

  path {
    fill: var(--theme-icon-fill);
  }
}
</style>
