<template lang="pug">
.row(v-if="!loading")
  .form-title
    .form-title-text {{ title }}
    .form-title-close(@click="closeForm({})")
      inline-svg.form-card-close-icon(
        :src="require('../../../../../assets/icons/form/header/close.svg')",
        @click="closeForm()"
      )
    .form-title-spacer
  step-one(:name="name", :facility="facility", @update-name="updateNameField", @update-facility="updateFacility")
  step-two(
    :readonly="facilityBlank",
    :buildings="getInitialValues(building)",
    :watch="getDynamicWatch('facility', facility.id)",
    :selectParams="{ facility_id: facility.id }",
    @update-building="updateBuilding"
  )
  step-three(
    :groupSystem="groupSystem",
    :systems="getInitialValues(system)",
    :equipments="getInitialValues(equipment)",
    :groupSystemReadonly="facilityBlank",
    :systemReadonly="groupSystemBlank",
    :equipmentReadonly="systemBlank",
    :groupSystemWatch="getDynamicWatch('facility', facility.id)",
    :systemWatch="getDynamicWatch('ppr_group_system_id', groupSystem.id)",
    :equipmentWatch="[ { name: 'building_id', value: getBuildingParams() }, { name: 'ppr_system_id', value: getSystemParams() }, ]",
    :groupSystemParams="{ facility_id: facility.id }",
    :systemParams="{ ppr_group_system_id: groupSystem.id }",
    :equipmentParams="{ ppr_system_id: getSystemParams(), building_id: getBuildingParams() }",
    :radioType="type",
    @update-group-system="updateGroupSystem",
    @update-system="updateSystem",
    @update-equipment="updateEquipment",
    @update-radio="updateType"
  )
  step-four(:events="events", @close-event="closeEvent", @add-event="addEvent")
  .form-submit-wrapper
    q-btn.form-submit(unelevated, no-caps, @click="submitForm()", :disable="disabledButton") {{ schedulerLocales["submit"] }}

  q-btn.history-button(v-if="props.method === 'edit'", unelevated, no-caps, @click="showHistoryModal()") {{ schedulerLocales["historyButton"] }}
  history-modal(
    v-if="showHistory",
    :show="showHistory",
    :path="`/api/v3/ppr/schedules/${props.id}/history`",
    @close="closeHistoryModal()"
  )
.row(v-else)
  .spinner-container.form-spinner
    q-spinner(color="primary", size="3em")
</template>

<script setup>
import { computed, ref, onBeforeMount, onMounted, provide } from "vue";
import selectField from "../../../../shared/general_components/fields/selectField";
import dateField from "../../../../shared/general_components/fields/dateField";
import historyModal from "../../../../shared/historyModal";
import backend from "@/api";
import i18n from "@/plugins/vue-i18n";
import { handleError } from "@/services/handleErrors";
import { useStore } from "@/store";
import { Notify } from "quasar";
import { getDynamicWatch } from "@/services/useWatchParent";
import { currentLocale, pprScheduleLocales } from "@/services/useLocales";
import { parse } from "date-fns";
import stepOne from "./stepOne";
import stepTwo from "./stepTwo";
import stepThree from "./stepThree";
import stepFour from "./stepFour";

const props = defineProps({
  method: { type: String, default: () => "create" },
  title: { type: String, default: () => i18n["messages"][currentLocale.value]["scheduler"]["titleNew"] },
  id: { type: Number, default: () => null },
});
const emit = defineEmits(["close-form"]);
const store = useStore();

const name = ref("");
const facility = ref({});
const building = ref([]);
const buildingsToRemove = ref([]);
const groupSystem = ref({});
const groupSystemToRemove = ref(null);
const system = ref([]);
const systemToRemove = ref([]);
const type = ref("building");
const equipment = ref([]);
const equipmentToRemove = ref([]);
const events = ref([]);
const eventsToRemove = ref([]);
const checklistOptions = ref([]);
const loading = ref(true);
const formSubmiting = ref(false);
const disabledButton = ref(false);
const showHistory = ref(false);

const systemBlank = computed(() => !system.value.length > 0);
const facilityBlank = computed(() => !facility.value.id);
const groupSystemBlank = computed(() => !groupSystem.value.id);

const schedulerLocales = computed(() => i18n["messages"][currentLocale.value]["scheduler"]);
const dateLocales = computed(() => i18n["messages"][currentLocale.value]["date"]);
const periodTypeOptions = computed(() => [
  {
    id: "daily",
    title: pprScheduleLocales.value["form.daily"],
  },
  {
    id: "weekly",
    title: pprScheduleLocales.value["form.weekly"],
  },
  {
    id: "monthly",
    title: pprScheduleLocales.value["form.monthly"],
  },
  {
    id: "yearly",
    title: pprScheduleLocales.value["form.yearly"],
  },
]);
const path = computed(() => store.state.paths["ppr_schedule"]);

const closeForm = data => {
  emit("close-form", data);
};

const getInitialValues = values => {
  const arr = [];
  values.forEach(item => {
    arr.push(item.id);
  });
  return arr;
};

const updateFacility = async val => {
  facility.value = {
    id: val.value,
    title: val.title,
  };
  building.value = [];
  groupSystem.value = {};
  system.value = [];
  equipment.value = [];
  events.value = [];
};

const updateBuilding = val => {
  building.value = [];
  val.forEach(item => {
    building.value.push({
      id: item.value,
      title: item.title,
    });
  });
};

const updateGroupSystem = val => {
  groupSystem.value = {
    id: val.value,
    title: val.title,
  };
};

const updateSystem = val => {
  system.value = [];
  val.forEach(item => {
    system.value.push({
      id: item.value,
      title: item.title,
    });
  });
};

const updateEquipment = val => {
  equipment.value = [];
  val.forEach(item => {
    equipment.value.push({
      id: item.value,
      title: item.title,
    });
  });
};

const updateType = val => {
  type.value = val;
};

const getSystemParams = () => {
  const systems = [];
  system.value.forEach(item => {
    systems.push(item.id);
  });
  return systems;
};

const getBuildingParams = () => {
  let buildings = [];
  buildings = building.value.map(item => {
    return item.id;
  });
  return buildings;
};

const addEvent = async () => {
  if (!facility.value.id) {
    return;
  }
  const event = {
    name: "",
    description: "",
    periodType: {},
    period: [],
    startedAt: "",
    finishedAt: "",
    works: await getInitialWorks(),
    materials: [],
    materialsToRemove: [],
    materialsOptions: [],
    deadline: "",
    id: null,
  };
  events.value.push(event);
};

const getInitialWorks = async (query = "", page = 1, selected = [], prevOptions = []) => {
  const params = {
    facility_id: facility.value.id,
    scheduler: true,
    search_query: query,
    infinite_scroll: {
      page: page,
      per_page: 20,
    },
  };
  const works = {
    options: [...prevOptions],
    items: [],
    toRemove: [],
    hasNextPage: false,
    page: page,
  };
  try {
    const { data } = await backend.collection(`${store.state.paths["work"]}/collection`, params);
    data.options.forEach(option => {
      option["selected"] = false;
      works.options.push(option);
    });
    if (hasNextPage(works.page, data.count)) {
      works.hasNextPage = true;
      works.page++;
    }
    if (selected && selected.length > 0) {
      selected.forEach(item => {
        works.items.push({
          id: item.work.id,
          title: item.work.title,
          cost: item.work.cost,
          standard_hours: item.work.standard_hours,
          count: Number(item.count) - 1,
          selected: true,
        });
        works.toRemove.push(item.id);
      });
      works.options.map(option => {
        if (works.items.findIndex(work => work.id === option.id) !== -1) {
          option.selected = true;
        }
        return;
      });
    }
  } catch (err) {
    await handleError(err);
  }
  return works;
};

const hasNextPage = (page, count) => {
  const lastPage = Math.ceil(count / 20);
  return page < lastPage;
};

const searchWorks = async (event, query) => {
  await getWorks(event, query, true);
};

const getWorks = async (event, query, searching = false) => {
  const params = {
    facility_id: facility.value.id,
    scheduler: true,
    search_query: query,
    infinite_scroll: {
      page: searching ? 1 : event.works.page,
      per_page: 20,
    },
  };
  const data = await getWorksData(params);
  if (searching) {
    event.works.options = [];
  }
  data.options.forEach(option => {
    option["selected"] = false;
    event.works.options.push(option);
  });
  if (hasNextPage(event.works.page, data.count)) {
    event.works.hasNextPage = true;
    event.works.page++;
  } else {
    event.works.hasNextPage = false;
  }
  event.works.options.map(option => {
    if (event.works.items.findIndex(work => work.id === option.id) !== -1) {
      option.selected = true;
    }
    return;
  });
};

const getWorksData = async params => {
  let data;
  try {
    const resp = await backend.collection(`${store.state.paths["work"]}/collection`, params);
    data = resp.data;
  } catch (err) {
    await handleError(err);
  }
  return data;
};

const getMaterials = async id => {
  const params = {
    facility_id: id,
    scheduler: true,
  };
  const options = [];
  try {
    const { data } = await backend.collection(`${store.state.paths["material"]}/collection`, params);
    data.options.forEach(option => {
      option["selected"] = false;
      options.push(option);
    });
  } catch (err) {
    await handleError(err);
  }
  return options || [];
};

const closeEvent = () => {
  const el = event.target;
  const eventEl = el.closest(".form-step-four-event");
  eventEl.classList.toggle("close");
};

const closeWorks = () => {
  const el = event.target;
  const worksEl = el.closest(".form-step-four-event-works-left");
  worksEl.classList.toggle("close");
};

const closeMaterials = () => {
  const el = event.target;
  const worksEl = el.closest(".form-step-four-event-materials-left");
  worksEl.classList.toggle("close");
};

const selectWork = (event, option) => {
  const optionInEventIndex = event.works.items.findIndex(work => work.id === option.id);
  if (optionInEventIndex !== -1) {
    option["selected"] = false;
    option.count = 0;
    event.works.items.splice(optionInEventIndex, 1);
    if (event.works.options.find(item => item.id === option.id)) {
      event.works.options.find(item => item.id === option.id).selected = false;
    }
  } else {
    option["selected"] = true;
    event.works.items.push(option);
  }
};

const selectMaterial = (event, option) => {
  const optionInEventIndex = event.materials.findIndex(work => work.id === option.id);
  if (optionInEventIndex !== -1) {
    option["selected"] = false;
    option.count = 0;
    event.materials.splice(optionInEventIndex, 1);
    event.materialsOptions.find(item => item.id === option.id).selected = false;
  } else {
    option["selected"] = true;
    event.materials.push(option);
  }
};

const changeCount = (operator, option) => {
  if (operator === "-" && option.count - 1 == -1) {
    return;
  }
  operator === "+" ? option.count++ : option.count--;
};

const calculateCost = (count, cost) => {
  if (!cost) {
    return null;
  }
  return rounded(count * cost);
};

const getCalculateCost = (count, cost) => {
  return calculateCost(count, cost) ? calculateCost(count, cost) : null;
};

const calculateHours = (count, hours) => {
  if (!hours) {
    return 0;
  }
  return rounded(count * hours);
};

const getAllHours = event => {
  let summ = 0;
  event.works.items.forEach(work => {
    summ += calculateHours(work.count + 1, work.standard_hours);
  });
  return summ !== 0 ? rounded(summ) : null;
};

const getAllCosts = items => {
  let summ = 0;
  items.forEach(item => {
    summ += calculateCost(item.count + 1, item.cost);
  });
  return summ !== 0 ? `${rounded(summ)}₽` : null;
};

const getTotalCost = event => {
  let summ = 0;
  event.materials.forEach(material => {
    summ += calculateCost(material.count + 1, material.cost);
  });
  event.works.items.forEach(work => {
    summ += calculateCost(work.count + 1, work.cost);
  });
  return summ !== 0 ? `${rounded(summ)}₽` : null;
};

const updateChecklist = value => {
  value.event.checklist = value.val;
};

const updatePeriod = (val, event) => {
  event.periodType = {
    id: val.value,
    title: val.title,
  };
  setPeriodOptions(event);
};

const setPeriodOptions = event => {
  switch (event.periodType.id) {
    case "daily":
      event.period = [
        {
          id: 1,
          title: dateLocales.value.daysShort[1],
          selected: false,
        },
        {
          id: 2,
          title: dateLocales.value.daysShort[2],
          selected: false,
        },
        {
          id: 3,
          title: dateLocales.value.daysShort[3],
          selected: false,
        },
        {
          id: 4,
          title: dateLocales.value.daysShort[4],
          selected: false,
        },
        {
          id: 5,
          title: dateLocales.value.daysShort[5],
          selected: false,
        },
        {
          id: 6,
          title: dateLocales.value.daysShort[6],
          selected: false,
        },
        {
          id: 0,
          title: dateLocales.value.daysShort[0],
          selected: false,
        },
      ];
      break;

    case "weekly":
      event.period = [];
      for (let i = 0; i < 52; i++) {
        event.period.push({
          id: i + 1,
          title: i + 1,
          selected: false,
        });
      }
      break;

    case "monthly":
      event.period = [];
      for (let i = 1; i <= 12; i++) {
        const date = new Date(`2022-${i}-01`);
        event.period.push({
          id: i,
          title: date.toLocaleString(currentLocale.value, { month: "long" }),
          selected: false,
        });
      }
      break;

    case "yearly":
      event.period = [];
      const date = new Date();
      let currentYear = date.getFullYear();
      const lastYear = currentYear + 17;
      while (currentYear + 1 < lastYear) {
        event.period.push({
          id: currentYear + 1,
          title: currentYear + 1,
          selected: false,
        });
        currentYear++;
      }
      break;

    default:
      event.period = [];
      break;
  }
};

const setPeriodOptionSelected = option => {
  option.selected ? (option.selected = false) : (option.selected = true);
};

const selectStartDate = value => {
  value.event.startedAt = value.val;
};

const selectFinishDate = value => {
  value.event.finishedAt = value.val;
};

const submitForm = async () => {
  if (formSubmiting.value) {
    return;
  }

  if (validForm()) {
    const schedule = getParams();
    let res;
    try {
      disabledButton.value = true;
      if (props.method === "create") {
        res = await backend.create(path.value, "", { schedule });
      } else {
        res = await backend.put(`${path.value}/${props.id}`, { schedule });
      }
      formSubmiting.value = true;
      const data = {};
      data.data = res.data;
      data.method = props.method;
      closeForm(data);
    } catch (err) {
      await handleError(err);
    } finally {
      disabledButton.value = false;
    }
  }
};

const getParams = () => {
  const params = {
    title: name.value,
    facility_id: facility.value.id,
    planning_by: type.value,
    schedule_placements_attributes: [],
    schedule_layouts_attributes: [],
    schedule_events_attributes: [],
  };
  if (building.value.length > 0 || buildingsToRemove.value.length > 0) {
    building.value.forEach(item => {
      params.schedule_placements_attributes.push({
        id: null,
        placement_type: "Building",
        placement_id: item.id,
      });
    });
    buildingsToRemove.value.forEach(id => {
      params.schedule_placements_attributes.unshift({
        _destroy: 1,
        id: id,
      });
    });
  }
  if (type.value !== "equipment") {
    equipment.value = [];
  }
  if (equipment.value.length > 0) {
    equipment.value.forEach(item => {
      params.schedule_layouts_attributes.push({
        id: null,
        layout_id: item.id,
        layout_type: "Ppr::Equipment",
      });
    });
    if (systemToRemove.value.length > 0) {
      systemToRemove.value.forEach(id => {
        params.schedule_layouts_attributes.unshift({
          id: id,
          _destroy: 1,
        });
      });
    }
    equipmentToRemove.value.forEach(id => {
      params.schedule_layouts_attributes.unshift({
        id: id,
        _destroy: 1,
      });
    });
  } else if (system.value.length > 0) {
    system.value.forEach(item => {
      params.schedule_layouts_attributes.push({
        id: null,
        layout_id: item.id,
        layout_type: "Ppr::System",
      });
    });
    if (equipmentToRemove.value.length > 0) {
      equipmentToRemove.value.forEach(id => {
        params.schedule_layouts_attributes.unshift({
          id: id,
          _destroy: 1,
        });
      });
    }
    systemToRemove.value.forEach(id => {
      params.schedule_layouts_attributes.unshift({
        id: id,
        _destroy: 1,
      });
    });
  } else if (groupSystem.value.id) {
    params.schedule_layouts_attributes.push({
      id: null,
      layout_id: groupSystem.value.id,
      layout_type: "Ppr::GroupSystem",
    });
    if (groupSystemToRemove.value) {
      params.schedule_layouts_attributes.unshift({
        _destroy: 1,
        id: groupSystemToRemove,
      });
    }
  }
  events.value.forEach(event => {
    const eventPeriods = [];
    const eventWorks = [];
    const eventMaterials = [];
    event.period.forEach(period => {
      if (period.selected) {
        eventPeriods.push(period.id);
      }
    });
    event.materials.forEach(material => {
      eventMaterials.push({
        id: null,
        material_id: material.id,
        count: material.count + 1,
      });
    });
    event.materialsToRemove.forEach(id => {
      eventMaterials.unshift({
        _destroy: 1,
        id: id,
      });
    });
    event.works.items.forEach(work => {
      eventWorks.push({
        id: null,
        work_id: work.id,
        count: work.count + 1,
      });
    });
    event.works.toRemove.forEach(id => {
      eventWorks.unshift({
        _destroy: 1,
        id: id,
      });
    });
    params.schedule_events_attributes.push({
      title: event.name,
      description: event.description,
      period_type: event.periodType.id,
      period: eventPeriods,
      event_works_attributes: eventWorks,
      event_materials_attributes: eventMaterials,
      started_at: event.startedAt,
      finished_at: event.finishedAt,
      days_to_overdue: event.deadline,
      id: event.id ? event.id : null,
      // позже добавим на беке
      // checklist_id: event.checklist.id,
    });
  });
  return params;
};

const validForm = () => {
  if (name.value.length <= 0) {
    Notify.create(pprScheduleLocales.value["form.validations.name"]);
    return false;
  }
  if (!facility.value.id) {
    Notify.create(pprScheduleLocales.value["form.validations.facility"]);
    return false;
  }
  if (system.value.length === 0) {
    Notify.create(pprScheduleLocales.value["form.validations.system"]);
    return false;
  }
  if (events.value.length === 0) {
    Notify.create(pprScheduleLocales.value["form.validations.event"]);
    return false;
  }
  let eventErr = "";
  events.value.forEach(event => {
    if (!event.name) {
      if (events.length > 1) {
        eventErr = pprScheduleLocales.value["form.errors.events.name"];
      } else {
        eventErr = pprScheduleLocales.value["form.errors.event.name"];
      }
      return;
    }
    if (!event.periodType.id || !event.period.length > 0) {
      if (events.length > 1) {
        eventErr = pprScheduleLocales.value["form.errors.events.period"];
      } else {
        eventErr = pprScheduleLocales.value["form.errors.event.period"];
      }
      return;
    }
    if (!event.startedAt || !event.finishedAt) {
      eventErr = pprScheduleLocales.value["form.errors.event.started_at"];
      return;
    }
    if (!event.deadline) {
      eventErr = pprScheduleLocales.value["form.errors.event.deadline"];
      return;
    }
  });
  if (eventErr !== "") {
    Notify.create(eventErr);
    return false;
  }
  return true;
};

const getFormValues = async () => {
  try {
    if (props.method === "edit") {
      const { data } = await backend.show(path.value, props.id);
      name.value = data.title;
      facility.value = data.facility;
      data.schedule_placements.forEach(item => {
        building.value.push(item.placement);
        buildingsToRemove.value.push(item.id);
      });
      data.schedule_layouts.forEach(item => {
        if (item.layout_type === "Ppr::Equipment") {
          type.value = "equipment";
          equipment.value.push({
            id: item.layout.id,
            title: item.layout.full_title,
          });
          equipmentToRemove.value.push(item.id);
          groupSystem.value = {
            id: item.layout.system.group_system.id,
            title: item.layout.system.group_system.title,
          };
          if (!system.value.some(systemItem => systemItem.id === item.layout.system.id)) {
            system.value.push({
              id: item.layout.system.id,
              title: item.layout.system.title,
            });
          }
        } else if (item.layout_type === "Ppr::System") {
          systemToRemove.value.push(item.id);
          system.value.push({
            id: item.layout.id,
            title: item.layout.title,
          });
          groupSystem.value = {
            id: item.layout.group_system.id,
            title: item.layout.group_system.title,
          };
        } else if (item.layout_type === "Ppr::GroupSystem") {
          groupSystemToRemove.value === item.id;
          groupSystem.value = {
            id: item.layout.id,
            title: item.layout.title,
          };
        }
      });
      for (let i = 0; i < data.schedule_events.length; i++) {
        const item = data.schedule_events[i];
        const event = {
          name: item.title,
          description: item.description || "",
          periodType: periodTypeOptions.value.find(period => period.id === item.period_type),
          period: [],
          startedAt: parsedDate(item.started_at).toLocaleDateString(),
          finishedAt: parsedDate(item.finished_at).toLocaleDateString(),
          works: await getInitialWorks("", 1, item.event_works),
          materials: [],
          materialsToRemove: [],
          materialsOptions: await getMaterials(facility.value.id),
          deadline: item.days_to_overdue || "",
          id: item.id,
        };
        setPeriodOptions(event);
        item.period.forEach(item => {
          if (item) {
            const periodEl = event.period.find(i => i.id === Number(item));
            periodEl.selected = true;
          }
        });
        item.event_materials.forEach(eventItem => {
          event.materials.push({
            id: eventItem.material.id,
            title: eventItem.material.title,
            count: Number(eventItem.count) - 1,
            cost: eventItem.material.cost,
            selected: true,
          });
          event.materialsToRemove.push(eventItem.id);
        });
        event.materialsOptions.map(option => {
          if (event.materials.findIndex(material => material.id === option.id) !== -1) {
            option.selected = true;
          }
          return;
        });
        events.value.push(event);
      }
    }

    loading.value = false;
  } catch (err) {
    await handleError(err);
  }
};

const updateNameField = val => {
  name.value = val;
};

const resetEventName = event => {
  event.name = "";
};

const rounded = number => {
  return number ? +number.toFixed(2) : null;
};

const parsedDate = date => {
  // "UTC" приходит с бека в конце текстового формата времени при отсутствии разницы в таймзоне.
  // Если разница есть, то она приходит в формате +hhmm без UTC
  const dateWithTimezone = date.replace("UTC", "+0000");
  return parse(dateWithTimezone, "yyyy-MM-dd HH:mm:ss xx", new Date());
};

const showHistoryModal = () => {
  showHistory.value = true;
};

const closeHistoryModal = () => {
  showHistory.value = false;
};

provide("formFunctions", {
  closeEvent,
  resetEventName,
  updatePeriod,
  getAllHours,
  getAllCosts,
  addEvent,
  closeWorks,
  calculateHours,
  getCalculateCost,
  setPeriodOptionSelected,
  selectStartDate,
  selectFinishDate,
  changeCount,
  selectWork,
  getWorks,
  searchWorks,
});

provide("formVariables", {
  facility,
  periodTypeOptions,
});

onBeforeMount(async () => {});

onMounted(async () => {
  await getFormValues();
});
</script>

<style lang="scss"></style>
